<template>
    <div>
        <h1>Bartender view for...</h1>
        <div class="row userCard mb-2">
                                    <div class="mr-2">
                                       <span class="fa-stack fa-lg icon-gplus">
          <i class="fa fa-square fa-stack-2x"></i>
          <i class="fa fa-building text-light fa-stack-1x"></i>
          
        </span>

                                    </div>
                                   
                                    
                                </div>
    </div>    
</template>
<script>

export default {
  name: 'Bartender-View',
  components:{
    
  },
  data() {
    return {
       currentClubs:[{name:"Tester",address:"123 test drive Baltimore, MD 21227",bars:[{name:"1st floor",isHovering:false,barId:"1"},{name:"Rooftop",isHovering:false,barId:"2"}]}]
    };
  },
  methods:{
    getCurrentOrders:function(){
      alert("it starts")
      setInterval(() => {
        alert("I have gotten some new orders")
      }, 60000);
    }
  },
  mounted(){
  //  this.getCurrentOrders()
  }
}
</script>