<template>
<div class="mt-3">
    <h1>Reset Password</h1>
        <form class="ms-4">
  <div class="mb-3">
    <div class="mb-3">
    <label for="inputAddress" class="form-label float-left">Password</label>
    <input type="password" v-model="password" class="form-control ms-1" id="inputAddress" placeholder="Enter new password">
  </div>
<div class="mb-3">
    <label for="inputAddress" class="form-label float-left">Password Confirmation</label>
    <input type="password" v-model="passwordConfirm" class="form-control ms-1" id="inputAddress" placeholder="Enter new password">
  </div>

  </div>

    <button type="button" @click="reset()" class="btn btn-primary">Submit</button>
</form>

</div>
</template>
<script>
import UserService from "../api/user.js";
import swal from 'sweetalert';

export default {
  name: 'Reset-Password',
  components:{
  //  VueTimepicker
  },
    props: ['venue','index'],
  data() {
    return {
        name:"",
        password:"",
        addressSecond:"",
        passwordConfirm:"",
        emailAddress:"",

    };
  },
  methods:{
    reset(){
        if(!this.isMatching) {
           swal( "Error" , "Ensure the password and password confirm are the same" ,  "error" )
        }       
        else if(!this.isLengthAcceptable){
           swal( "Error" , "Ensure the password is at least 8 characters" ,  "error" )

        }
        else{
          const body = {
          password:this.password,
          passwordConfirm:this.passwordConfirm
        }
        UserService.resetPassword(this.resetToken,body).then(data =>{
          if (data === true){
            swal("Success", "Password has been updated!", "success");
               this.$router.push({ name: 'Login'})
            console.log(data)
          }
          else{
             swal( "Error" , "There was an error with submission. Please try again later" ,  "error" ) 
          }
        })
        }
        
    },
  },
  computed:{
    isMatching(){
        if(this.password==this.passwordConfirm){
            return true
        }
        return false;
    },
    isLengthAcceptable(){
        if(this.password.length>=8){
            return true;
        }
        return false;
    },
     resetToken(){
      return this.$route.params.resetToken
    }
  }
}
</script>
<style scoped>
.custom-label-height{
    height: 27px;
}

    

</style>