<template>
    <div>
          <h1>Setup Guide</h1>

        <h2>Step 1: Stripe Account Setup</h2>
        <ol>
            <li>Navigate to the <strong>Settings</strong> page by clicking on the gear icon located at the top left of the
                screen.</li>
            <li>Within the <strong>Settings</strong> page, find and click on the link to set up a Stripe account for
                seamless payment processing.</li>
        </ol>

        <h2>Step 2: Venue Configuration</h2>
        <ol>
            <li>Access the <strong>Inventory</strong> dropdown in the header.</li>
            <li>Choose the <strong>Venue</strong> option to create a venue object.</li>
            <li>Once the venue is created, proceed to the <strong>Bar</strong>, <strong>Menu</strong>, and <strong>Bathroom</strong> screens within the
                <strong>Inventory</strong> dropdown to input associated data for the venue.</li>
            <li>Enable, disable, or delete data as needed for each section.</li>
        </ol>

        <h2>Step 3: Barcode Generation</h2>
        <ol>
            <li>Navigate to the <strong>Operations</strong> dropdown in the header.</li>
            <li>Select the <strong>Generate Barcode</strong> screen to create a barcode for the venue.</li>
            <li>Alternatively, retrieve the necessary text for the barcode for self-printing.</li>
        </ol>

        <h2>Step 4: User Account Setup</h2>
        <ol>
            <li>For additional associates working for the business, instruct them to create their own account on the
                website.</li>
            <li>Associates should reference the Employee Code on the main screen during account creation.</li>
        </ol>

        <h2>Step 5: Managing Orders</h2>
        <ol>
            <li>The main account has the capability to accept orders.</li>
            <li>Associates can accept orders, enable/disable items, but have limited actions.</li>
            <li>To handle live orders, navigate to the <strong>Live Orders</strong> screen in the <strong>Operations</strong> dropdown.</li>
            <li>Process and manage orders by accepting or denying them.</li>
        </ol>

        <h2>Mobile App Usage</h2>
        <ul>
            <li>Users will utilize the mobile app to scan the barcode and create orders seamlessly.</li>
        </ul>
    </div>
</template>
<script>


export default {
  name: 'Instructions-Form',
  components:{
  //  VueTimepicker
  },
    props: ['venue','index'],
  data() {
    return {

    };
  },
  mounted(){

  },

}
</script>
<style scoped>
.custom-label-height{
    height: 27px;
}

h2{
    margin-top: 24px;
}
li{
    margin-top: 8px;
}

</style>