<template>
<div class="mt-3">
    <h1>Account Info</h1>
      <form class="ms-4 " @submit.prevent="signUp">
      <h3 class="mt-3">Generic Info</h3>
  <div class="mb-3">
    <div v-if="showError" class="alert alert-danger" role="alert">
          {{errorText}}
        </div>
    <label for="inputClubName" class="form-label float-left">Name</label>
    <input v-model="name" type="text" class="form-control ms-1" id="inputClubName" required>
  </div>
  <div class="">
    <label for="inputEmail" class="form-label float-left ">Email Address</label>
    <input type="email" v-model="emailAddress" class="form-control ms-1" id="inputEmail" required>
    <button type="button" v-on:click="updateGenericInfo" class="btn btnAccent my-3 float-right">Save</button>
  </div>
   
  
<hr class="rounded">
<h3 class="mt-3">Password Reset</h3>
  <div class="mb-3">
    <label for="oldPassword" class="form-label float-left">Old Password</label>
    <input type="password" v-model="oldPassword" class="form-control ms-1" id="oldPassword" required>
  </div>
  <div class="mb-3">
    <label for="password" class="form-label float-left">Password</label>
    <input type="password" v-model="password" class="form-control ms-1" id="password" required>
  </div>
<div class="mb-3">
    <label for="passwordConfirm" class="form-label float-left">Password Confirmation</label>
    <input type="password" v-model="passwordConfirm" class="form-control ms-1" id="passwordConfirm" required>
    <button type="button" v-on:click="updatePassword" class="btn btnAccent my-3 float-right">Save</button>
  </div>


  <hr class="rounded">
  <h3 class="mt-3">Stripe Account <i v-if="!setupStripe" class="ph-bold ph-warning warning"></i></h3>  
  <p v-if="setupStripe">
    You already setup your stripe account, navigate to the <a target="_new" href="https://dashboard.stripe.com">Stripe Dashboard</a> to see your information/ validate your account. Be aware that it may take 2-3 days before full access is given.
  </p>
  <div class="align" v-else>
    You need to create an account to recieve secure payments. Please create one here. It may take a few days to finish authentication:
    <button type="button" v-on:click="onboardPayments" class="btn btnAccent mx-2">Setup Account</button> 
    <img v-show="loading" class="loading" src="../assets/loading-loading-forever.gif"/>
  </div>

</form>

</div> 
</template>
<script>
import swal from 'sweetalert';
import 'vue2-timepicker/dist/VueTimepicker.css'
import userService from "../api/user.js"
import paymentService from '../api/payments.js'


export default {
  name: 'Club-Details',
  components:{
  //  VueTimepicker
  },
    props: ['venue','index'],
  data() {
    return {
        name:"",
        oldPassword:"",
        password:"",
        addressSecond:"",
        passwordConfirm:"",
        emailAddress:"",
        businessCode:"",
        showError:false,
        errorText:"",
        loading:false

    };
  },
  mounted(){
    if(localStorage.getItem('user')==null){
        swal( "Error" , "Screen data has been corrupted, please refresh and try again" ,  "error" )
      }
      else{
        console.log(this.$route.path)
        this.loading=true;
        userService.getGenericInfo(localStorage.getItem('user')).then(data=>{
           this.loading=false
          this.name = data.data.name
          this.emailAddress = data.data.email
          if(data.data.stripeID){
          localStorage.setItem('stripeAccountID', data.data.stripeID);
        }
        })
      }
    
  },
  computed:{
    setupStripe(){
      return localStorage.getItem('stripeAccountID') !== null;
    },
    userToken(){
      return encodeURIComponent(localStorage.getItem('user'));
    }
  },
  methods:{
    alertInfo(){
      swal("For Employees Only","Have whoever setup the business give you the 6 digit code to associate you to the business")
    },
    onboardPayments(){
       this.loading=true;
      paymentService.onboardUser(localStorage.getItem('user'));
    },
    updatePassword(){
      if(this.password.length<8){
        swal( "Error" , "Password must be at least 8 characters" ,  "error" )
      }
      else if(this.password!=this.passwordConfirm){
       swal( "Error" , "Password and Password Confirm don't match" ,  "error" )
      }
      else if(this.oldPassword==""){
        swal( "Error" , "Please enter old password" ,  "error" )
      }
      else{
        var body ={
            passwordCurrent:this.oldPassword,
            password: this.password,
            passwordConfirm: this.passwordConfirm
        }
         this.loading=true;
        userService.updatePassword(localStorage.getItem('user'),body)
      .then(data =>{
         this.loading=false;
        if(data.error){
           swal ( "Error" , "Something has gone wrong, please try again later" ,  "error" )
        }
        else{
           swal("Success", "Password has been updated!", "success");
        }

      });
      }
    },
    updateGenericInfo(){
      if(!this.checkIfEmail(this.emailAddress)){
      swal ( "Error" , "Please ensure that this is a valid email" ,  "error" )
      }
      else if(this.name.length==0){
          swal ( "Error" , "Name is required" ,  "error" )
      }
      else if(localStorage.getItem('user')==null){
        swal( "Error" , "Screen data has been corrupted, please refresh and try again" ,  "error" )
      }
      else {
       
        var user ={
            name:this.name,
            email: this.emailAddress,
        }
         this.loading=true;
            userService.updateGenericInfo(localStorage.getItem('user'),user)
      .then(data =>{
         this.loading=false;
        if(data.error){
           swal ( "Error" , "Something has gone wrong, please try again later" ,  "error" )

        }
        else{
           swal("Success", "User has been updated!", "success");
        }

      });
        
      }
    },
    checkIfEmail(str) {
  // Regular expression to check if string is email
  const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

  return regexExp.test(str);
}
  },
}
</script>
<style scoped>
.custom-label-height{
    height: 27px;
}
hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
  width: 100%;
}
.warning{
  color: purple;
}

    

</style>