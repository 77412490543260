<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Bar List</div>
        <button v-if="venueId!=0 && isBusiness" type="button" ref="action"  class="btn mb-3" data-bs-toggle="modal" data-bs-target="#newBarModal"> <i class="fa-solid fa-plus"></i></button>
    </div>
    <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
    <div class="row mt-4">
        <div v-for="(item,index) in barList" :key="index" class="card m-3" style="width: 18rem;">
  <div class="card-body">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <h6 class="card-sub-title text-muted mt-1">{{item.description}}</h6>
    <div class="btn-group" role="group">
       <button v-if="item.is_disabled" v-on:click="toggleEnabled(item)" type="button" class="btn btn-primary mx-1">Disabled</button>
        <button v-if="!item.is_disabled" v-on:click="toggleEnabled(item)" type="button" class="btn btn-primary mx-1">Enabled</button>
        <button v-if="isBusiness" type="button" v-on:click="openModal(item)" class="btn btn-primary mx-1">Edit</button>
        <button v-if="isBusiness" type="button" v-on:click="deleteBar(item._id,index)" class="btn btn-primary mx-1">Delete</button>

        

    </div>
  </div>
</div>
   </div>
    

<!-- Modal -->
<div class="modal fade" id="newBarModal" ref="modal" tabindex="-1" aria-labelledby="newBarModalLabel" aria-hidden="true">
  <AddNewBar :bar="currentBar" :venueId ="venueId" @addNewBar="addNewBar" @refreshEdit="refreshEdit" @updateBar="updateBar" />
</div>

  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import AddNewBar from "../components/modals/AddNewBar.vue"
import barService from "../api/bar.js"
import SelectVenue from "../components/SelectVenue.vue"
import swal from 'sweetalert';


export default {
  name: 'BarCreation',
  components:{
   AddNewBar,
   SelectVenue
  },
  data() {
    return {
        newItemType:"",
        barList:[],
        currentBar:"" ,
        venueId:0,
    };
  },
  computed:{
    isBusiness(){
      return localStorage.getItem('isBusiness');
    }
  },
  methods:{
    updateBar(item){
      var updateIndex = this.barList.findIndex((bar => bar._id === item._id))
      this.barList[updateIndex].name= item.name
      this.barList[updateIndex].description= item.description
    },
    refreshEdit(){
      this.currentBar=""
    },
    addNewBar(item){
      this.barList.push(item)
    },
    reset(){
      this.barList=[]
      this.venueId=0
    },
    deleteBar(id,index){
     swal({
    title: 'Are you sure you want to delete this bar?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // If confirmed, show a success message
       barService.deleteBarID(id)
       this.barList.splice(index,1)
      swal('Your bar has been deleted!', {
        icon: 'success',
      });
      // TODO: Perform the actual deletion action here
    } else {
      // Handle the cancellation
      swal('Your bar is safe!');
    }
  });
    },
    updateVenue(e){
      if(e.target.value!=0){
              barService.getAllBars(e.target.value).then(data =>{
        this.barList = data;
        this.venueId=e.target.value
     })
      }
      else{
        this.reset()
      }
    },
    toggleEnabled(item){
        var toggle = {
          is_disabled:!item.is_disabled
        }
          barService.updateBarByID(item._id,toggle).then(()=>{
            item.is_disabled=!item.is_disabled
         swal("Bar has been updated")
          })

    },
    openModal(item){
      this.currentBar=item;     
      this.$refs.action.click();
    },
    getImg(item){
        return item.image
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
