import axios from "axios";
import swal from 'sweetalert';


/*const http = axios.create({
    baseURL: 'https://foobar-app-backend.herokuapp.com/',
    timeout: 1000
  });*/

  const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, 
    timeout: 10000
  });

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 303) {
        return Promise.resolve(error.response);
      }
      if (error.response?.status >=500) {
        swal('Something has gone wrong, Please refresh and try again. If issue persists, please call 443-509-1389 or email.', {
          icon: "error", // You can customize the icon (info, success, error, warning, question)
        });
      }
    
      return Promise.reject(error);
    }
  );


  export default http;