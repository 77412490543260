<template>
    <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newBarModalLabel">Add New bar</h5>
        <button type="button" v-on:click="clear" class="btn-close" data-bs-dismiss="modal" ></button>
      </div>
      <div class="modal-body">
        <div class="mt-3">
        <label for="BarName" class="form-label">Bar Name</label>
    <input type="text" class="form-control pl-3" v-model="name" id="BarName" aria-describedby="emailHelp">
  </div>
  <div class="mt-3">
        <label for="Details" class="form-label">Club details</label>
        <textarea class="form-control pl-3" v-model="clubDetails" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
  </div>
      </div>
      <div class="modal-footer">
        <button type="button" ref="close" v-show="false"  class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" v-on:click="clear" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" v-on:click="saveItem()"  class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import barService from "../../api/bar.js"

export default {
  name: 'AddNewBar',
  props:["bar","venueId"],
  data() {
    return {
        name:"",
        clubDetails:"",
        update:false
    };
  },
  methods:{
    saveItem(){
        var bar = {
          name:this.name,
          venue_ID: this.$props.venueId,
          description: this.clubDetails,
          is_disabled:false
        }
        if(!this.update){
          barService.saveNewBar(bar).then(data=>{
          this.$emit("addNewBar",data)
          this.clear()
           this.$refs.close.click();
        })
        }
        else{
          barService.updateBarByID(this.$props.bar._id,bar).then(data=>{
          this.$emit("updateBar",data)
          this.clear()
           this.$refs.close.click();
        })
        }
        
    },
    clear(){
      this.name = ""
      this.clubDetails = ""
      this.update = false
      this.$emit("refreshEdit")
      this.$refs.close.click();
    }
  },
  watch:{
        bar(){
         if(this.$props.bar!=""){
            this.name=this.$props.bar.name
            this.clubDetails= this.$props.bar.description
            this.update=true;
        }
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
