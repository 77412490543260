import http from "./base";

class BathroomService{
    constructor(){}

    async saveNewBathroom(bathroomItem) {
        const url = `bathrooms`;
        var response = "";
        await http
            .post(url, bathroomItem,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            })

        return response;
    }

    async updateBathroomByID(id,body) {
        const url = `bathrooms/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async deleteBathroomID(id) {
        const url = `bathrooms/`+id;
        var response = "";
        await http
            .delete(url,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }


    async getAllBathrooms(venueID)  {
        const url = `bathrooms/getAll/`+venueID;

        var response = "";
        await http
            .get(url, {
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }



}

let service = new BathroomService();
export default service;



