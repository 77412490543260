<template>
  <div class="login-container">
    <div class="login-card">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div v-if="showError" class="alert alert-danger" role="alert">
          Sign in unsuccessful. Incorrect email or password
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required>
          <small id="forgotPassword" @click="toForgotPassword" class="form-text text-primary forgotPassword">Forgot Password</small>
        </div>
        <button type="submit" class="btn btnAccent">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props:['refresh'],
  data() {
    return {
      email: '',
      password: '',
      showError:false
    };
  },
  methods: {
    login() {
      // Implement your login logic here
      // You can access the email and password via this.email and this.password
      const credentials ={
        email:this.email,
        password:this.password
      }
       this.$store.dispatch('login',credentials).then(()=>{
        if(localStorage.getItem('token')!==null){
         this.$router.go()
       }
       else{
        this.showError=true;
       }
       })
       
       
    },
    toForgotPassword(){
      this.$router.push({ name: 'Forgot-Password'})
    }
    
  },
  mounted(){
    if(this.$props.refresh){
      this.$router.go()
    }
    if(this.isSignedIn){
     this.$router.push({ name: 'Main-Landing'})
    }
  },
  computed:{
      isSignedIn(){
        if(localStorage.getItem('token')){
          return true;
        }
        return false;
      }
    }
};
</script>

<style scoped>

.forgotPassword:hover{
  text-decoration: underline;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}
</style>
