<template>
  <div id="app">
    <MenuBar />
    <router-view class="container"/>
  </div>
</template>

<script>
import MenuBar from "./views/MenuBar.vue"

export default {
  name: 'App',
  components: {
    MenuBar
  },
  mounted(){
    
  }
  
  
}
</script>


<style>
  @import './assets/app.css';
</style>