<template>
<div class="mt-3">
    <h1>Club Details</h1>
        <form class="ms-4">
  <div class="mb-3">
    <label for="inputClubName" class="form-label float-left">Club Name</label>
    <input v-model="name" type="text" class="form-control ms-1" id="inputClubName" placeholder="Enter the club name">
  </div>
  <div class="mb-3">
    <label for="inputAddress" class="form-label float-left">Address</label>
    <input v-model="addressFirst" type="text" class="form-control ms-1" id="inputAddress" placeholder="Enter the club address">
    <input type="text" v-model="addressSecond" class="form-control ms-1 mt-2" id="inputAddress" placeholder="Optional Add..">
  </div>
  <div class="mb-3">
    <div class="row">
        <div class="col-4">
           <label for="inputAddress" class="form-label float-left">City</label>
    <input v-model="city" type="text" class="form-control ms-1" id="inputAddress" placeholder="Enter the club address">
        </div>
        <div class="col-4">
           <label for="inputAddress" class="form-label float-left">State</label>
    <input v-model="state" type="text" class="form-control ms-1" id="inputAddress" placeholder="Enter the club address">
        </div>
        <div class="col-4">
           <label for="inputAddress" class="form-label float-left">ZipCode</label>
    <input v-model="zipCode" type="text" class="form-control ms-1" id="inputAddress" placeholder="Enter the club address">
        </div>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="inputSecurityPhone" class="form-label text-left w-50 text-start custom-label-height">Security Phone Number<button type="button"  class="btn col display-inline pt-0"> <i class="fa-solid fa-plus" @click="addNumber()"></i></button></label>
    <div v-for="(number,index) in securityPhoneNumbers" :key="index" class="row mb-3">
        <div class="col-md-4">
           <input type="tel" class="form-control ms-1" id="inputSecurityPhone" placeholder="+1-(XXX)-XXX-XXXX" v-model="securityPhoneNumbers[index]">
        </div>
        <div class="col-md-1">
            <button type="button"  class="btn float-left"> <i class="fa-solid fa-trash-can" @click="removeNumber(index)"></i></button>   
        </div>
    </div>
  </div>

  
  
  <div class="mb-3">
    <label for="inputClubDetails" class="form-label float-left ">Club Description</label>
    <textarea v-model="description" class="form-control" id="inputClubDetails" rows="4" placeholder="Enter the club details"></textarea>
  </div>
 <!-- <div class="mb-3 row">
    <label for="inputSecurityPhone" class="form-label text-left w-50 text-start custom-label-height">Operation Hours<button type="button"  class="btn col display-inline pt-0"> <i class="fa-solid fa-plus" @click="addCounter()"></i></button></label>

  </div>



  <div class="mb-3">
    <div v-for="(item,index) in timeCounts" :key="index" class="row">
        <div class="col-3">
                                <button class="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown">
                                    {{ item.Day }}
                                </button>
                                <div class="dropdown-menu"  role="menu" aria-labelledby="dropdownMenuButton">
                                    <button v-for="(type, index) in DaysofWeek"
                                         v-bind:key="index"
                                         type="button"
                                         href="#"
                                         class="dropdown-item"
                                         @click="changeDay(item,type)">
                                        {{ type }}
                                    </button>
                                </div>
        </div>
        <div class="col-4">
            <label>Start time: </label>
        <vue-timepicker v-model="item.startTime" :minute-interval="30" class=" mx-3" format="hh:mm A"></vue-timepicker>
        </div>
        <div class="col-4">
            <label>End time: </label>
        <vue-timepicker v-model="item.endTime" :minute-interval="30" class="mx-3" format="hh:mm A"></vue-timepicker>
        </div>
        <div class="col-1">
            <button type="button"  class="btn"> <i class="fa-solid fa-trash-can" @click="removeItem(item)"></i></button>
        </div>
    </div> 
  </div> -->
  <div class="mt-2 mb-3 row">
    <label for="inputSecurityPhone" class="form-label text-left w-50 text-start custom-label-height">Is Disabled<input type="checkbox" v-model="isDisabled" class="form-check-input ms-2" id="inputIsDisabled"></label>

  </div>
  <div class="mb-3">
     <img v-if="previewImage" :src="previewImage" class="uploading-image card-picture-size" />
    <input type="file" accept="image/jpeg" @change="uploadImage" :v-model="picture">
  </div>
    <button type="button" @click="saveVenue()" class="btn btn-primary">Submit</button>
</form>

</div>
</template>
<script>
//import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import venueService from "../api/venue.js"
//import {states} from "../assets/staticData.js"

export default {
  name: 'Club-Details',
  components:{
  //  VueTimepicker
  },
    props: ['venue','index'],
  data() {
    return {
        name:"",
        addressFirst:"",
        addressSecond:"",
        city:"",
        state:"",
        zipCode:"",
        image:"",
        emailAddress:"",
        securityPhoneNumbers:[""],
        description:"",
        isDisabled:false,
        runningCountofTimes:0,
        timeCounts:[{startTime:"",endTime:"",Day:"Select Day"}],
        DaysofWeek:["Every Day","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday","Weekday","Weekend"],
        picture:"",
        previewpicture:null,
        currentClubID:0,
        previewImage:null,

    };
  },
  mounted(){
    if(this.$props.venue){
      this.name=this.$props.venue.venue_name
      this.addressFirst=this.$props.venue.address
      this.city = this.$props.venue.city
      this.state = this.$props.venue.state
      this.zipCode = this.$props.venue.zipCode
      this.emailAddress=this.$props.venue.email_address
      this.securityPhoneNumbers=this.$props.venue.security_phone_number
      this.previewImage=this.$props.venue.picture
      this.isDisabled=this.$props.venue.is_disabled
      this.description=this.$props.venue.club_description

      this.currentClubID = this.$props.venue._id
    }
  },
  methods:{
    changeDay(item,day){
        item.Day= day
    },
    removeItem(item){
        var index= this.timeCounts.indexOf(item)
        this.timeCounts.splice(index,1)
    },
    removeNumber(index){
        this.securityPhoneNumbers.splice(index,1)
    },
    addNumber(){
        this.securityPhoneNumbers.push("")
    },
    addCounter(){
        var timeObject= {startTime:"",endTime:"",Day:"Select Day"}
        this.timeCounts.push(timeObject)
    },
     saveFile() {
                // eslint-disable-next-line no-console
                console.log(this.$refs.file.files[0]);
                this.image = this.$refs.file.files[0];
                
            },
    saveVenue(){
        var club ={
            venue_name:this.name,
            picture:this.previewImage,
            address: this.addressFirst + " " + this.addressSecond,
            security_phone_number: this.securityPhoneNumbers,
            club_description: this.description,
            is_disabled: this.isDisabled,
            user_id: localStorage.getItem('user'),
            city: this.city,
            state: this.state,
            zipCode: this.zipCode

        }
        if(this.currentClubID!=0){
            venueService.updateVenueByID(this.currentClubID,club)
      .then(data =>{
        if(data.error){
          alert(data.message)
        }
        else{
          console.log("Modal pop-up Update successful!")
        this.$store.state.venues.splice(this.$props.index,1)
        this.$store.state.venues.push(data);
        this.$router.push({ path: '/Venue-List/' })
        }
        

      });
        }
        else{
            venueService.saveNewVenue(club)
      .then(data =>{
         if(data.error){
          alert(data.message)
        }
        else{
          console.log("Modal pop-up save successful!")
        this.$store.state.venues.push(data);
        this.$router.push({ path: '/Venue-List/' })
        } 
      }); 
        }
        
    },
    uploadImage(e){
                this.picture = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.picture);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);
                };
            }
  }
}
</script>
<style scoped>
.custom-label-height{
    height: 27px;
}

    

</style>