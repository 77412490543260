<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Generate Barcode</div>
        <button type="button" ref="action"  class="ms-3 btn btnAccent mb-2 mb-3" @click="generateQrCode()">Generate</button>
        <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
    </div>
    <p class="mt-3" v-if="currentVenueId">If you wish to print your own barcode, it will need to show the following: {{currentVenueId}}</p>
        <img v-if="qrcodeString" :src="qrcodeString" class="uploading-image card-picture-size barcodeBorder" />

  </div>
</template>

<script>
import SelectVenue from "../../components/SelectVenue.vue"
const qr = require("qrcode")

export default {
  name: 'GenerateQRCode',
  components:{
   SelectVenue
  },
  data() {
    return {
        newItemType:"",
        currentVenueId:"",
        venueList:[{Name:"The Park at 14th",Address:"",Description:"Immediatly on your left when entering",isEnabled:false}],       
        barList:[{Name:"RoofTop",Address:"",Description:"Immediatly on your left when entering",isEnabled:false}],   
        qrcodeString:""    
    };
  },
  methods:{
    updateVenue(e){
      this.currentVenueId = e.target.value
    },
    generateQrCode(){
        var vue = this;

        qr.toDataURL(this.currentVenueId,function(err,code){
            if(err){
                console.log("there was an issue with barcode")
            }
            else{
                vue.qrcodeString=code
            }
        })
    },
    openModal(item){
      this.currentBar=item;
      this.$refs.action.click();
    },
    getImg(item){
        return item.image
    }
  },
  computed:{
    cocktailList(){
        return this.menu.filter(function(item) {

      return item.Type=="cocktail"
    });
    
    },
    wineList(){
        return this.menu.filter(function(item) {

      return item.Type=="wine"
    });
    
    },
    beerList(){
        return this.menu.filter(function(item) {

      return item.Type=="beer"
    });
    
    },
    foodList(){
        return this.menu.filter(function(item) {

      return item.Type=="food"
    });
    
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.barcodeBorder {
    margin: 50px;
    padding: 15px;
    border:5px solid purple;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}
</style>
