import http from "./base";

class UserService{
    constructor(){}

    async login(credentials) {
        const url = `users/login`;

        const {email,password} = credentials;

        var response = "";
        await http
            .get(url, {
                params:{
                    email:email,
                    password:password
                },
                cache: false
            })
            .then(responseData => {
                console.log(responseData)
                response = responseData.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }
    async getGenericInfo(id) {
        const url = `users/getGenericInfo/`+id;


        var response = "";
        await http
            .get(url, {
                params:{
                },
                cache: false
            })
            .then(responseData => {
                console.log(responseData)
                response = responseData.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }


    async updateGenericInfo(id,body) {
        const url = `users/updateGenericInfo/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error.response.data;
            });

        return response;
    }



    async updatePassword(id,body) {
        const url = `users/updatePassword/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data;
            })
            .catch(error => {
                response = error.response.data;
            });

        return response;
    }


    async resetPassword(token,body) {
        const url = `users/resetPassword/`+token;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                  },
            })
            .then( () => {
                response = true
            })
            .catch(error => {
                response = error.response.data;
            });

        return response;
    }



    async forgotPassword(email) {
        const url = `users/forgotPassword`;

        var response = "";
        await http
            .post(url, {
                email
            })
            .then(() => {
               response= true;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async signUp(payload) {
        const url = `users/signup`;
        const {email,name,password,passwordConfirm,businessCode} = payload

        var response = "";
        await http
            .post(url, {
                name,
                email,
                password,
                passwordConfirm,
                businessCode
            })
            .then(responseData => {
                response = responseData.data;
            })
            .catch(error => {
                response = error.response;
            });

        return response;
    }

    async getUserByID(id) {
        const url = `venues/`+id;

        var response = "";
        await http
            .get(url, {
                params:{
                  //  userId:userId
                },
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async saveNewUser(venueObject) {
        const url = `venues`;
        var response = "";
        await http
            .post(url, venueObject)
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async updateUserByID(id,body) {
        const url = `venues/`+id;
        var response = "";
        await http
            .patch(url,body)
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async deleteUserByID(id) {
        const url = `venues/`+id;
        var response = "";
        await http
            .delete(url)
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }



}

let service = new UserService();
export default service;



