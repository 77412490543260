<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Venue List</div>
        <router-link class="btn mb-3" to="/Club-Details"> <i class="fa-solid fa-plus"></i></router-link>
    </div>
    <div class="mt-4">
        <div v-for="(item,index) in venueList" :key="index" class="row card m-3" >
  <div class="card-body">
    <h5 class="card-title mt-1">{{item.venue_name}}</h5>
    <h6 class="card-sub-title text-muted mt-1">{{item.address}}</h6>
    <div class="btn-group" role="group">
        <button type="button" v-on:click="navigateToClubEdit(item,index)" class="btn btn-primary mx-1">Edit</button>
        <button type="button" v-on:click="deleteClub(item)" class="btn btn-primary mx-1">Delete</button>

        

    </div>
  </div>
</div>
   </div>
    

  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import venueService from "../api/venue.js"
import swal from 'sweetalert';

export default {
  name: 'VenueList',

  data() {
    return {
        newItemType:""
    };
  },
  mounted(){
    if(!this.$store.state.loadedVenues){
       this.$store.dispatch('venueLookup')
    }
  },
  methods:{
    toggleEnabled(item){
        item.isEnabled=!item.isEnabled
    },
    getImg(item){
        return item.image
    },
    navigateToClubEdit(item,index){
        this.$router.push({ name: 'Club-Details', params: { venue:item, index:index } })
    },
    deleteClub(item){
      swal({
    title: 'Are you sure you want to delete '+item.venue_name+ '?',
    text: 'You won\'t be able to revert this and all other data assocaiated will be lost!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // If confirmed, show a success message
        venueService.deleteVenueByID(item._id).then(()=>{
             swal('Delete successful!');
              var index = this.venueList.indexOf(item)
              this.$store.state.venues.splice(index,1)
            })
      
      // TODO: Perform the actual deletion action here
    } else {
      // Handle the cancellation
      swal('Your venue is safe!');
    }
  });
    }
  },
  computed:{
    venueList(){
      return this.$store.state.venues
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
