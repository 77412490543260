import http from "./base";



class PaymentService{
    constructor(){}

    async onboardUser(user) {
        const url = 'payments/onboardUser/'+user;
        await http
            .post(url, {},{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  timeout: 5000,
            })
            .then(response  => {
              if(response.data.user.chargesEnabled){
                localStorage.setItem('stripeAccountID',response.data.user.stripeAccountID); 
              }
               window.location.href = response.data.url
            })
            .catch(error => {
              console.log(error.response.data);
            });
    }


}

let service = new PaymentService();
export default service;



