<template>
    <div>
          
    <header>
        <h1>About Pulse</h1>
    </header>

    <section>
        <h2>Our Story</h2>
        <p>
            Pulse was born from the vibrant energy of nightlife, fueled by a desire to address common challenges observed by
            avid club-goers. Our founder, a familiar face in the club scene, envisioned a technological solution to
            revolutionize the way people connect, experience, and enjoy their nights out.
        </p>

        <h2>Mission</h2>
        <p>
            <strong>Maximize Fun, Minimize Hassle:</strong> At Pulse, our mission is clear – to maximize the enjoyment of
            your night out while minimizing the hassles. We strive to create new avenues for connection, provide valuable
            information, and empower users to shape their own unique experiences.
        </p>
        <p>
            <strong>Safety First:</strong> Your safety and well-being are our top priorities. Pulse integrates
            cutting-edge safety features, including direct communication lines to security, ensuring everyone can revel in
            a good time securely.
        </p>

        <h2>Current Features</h2>
        <ul>
            <li><strong>Point of Sale (POS):</strong> Seamlessly place and manage orders, enhancing the efficiency of
                your night out.</li>
            <li><strong>Bathroom List:</strong> Easily locate and access information about the venue's bathroom facilities
                for added convenience.</li>
        </ul>

        <h2>Future Enhancements</h2>
        <p>
            Pulse is continually evolving to bring you even more exciting features! In the near future, we plan to introduce
            in-app chat for connecting with friends, DJ interaction for a personalized music experience, event information for
            seamless navigation, and exclusive dating features within the bar.
        </p>

        <h2>Who We Serve</h2>
        <p>
            Pulse is tailored for bars, nightclubs, and event hosts looking for a POS system designed specifically for
            social events. Whether you're organizing a themed party, live performance, or a night at the club, Pulse is
            your ideal partner in creating unforgettable experiences.
        </p>

        <h2>Experience Pulse</h2>
        <p>
            Join us on a journey to redefine nightlife. Pulse is more than an app; it's a movement that transforms
            ordinary nights into extraordinary memories. Give your night new life with Pulse – your ultimate companion for an
            unforgettable experience.
        </p>
    </section>
    </div>
</template>
<script>


export default {
  name: 'About-Pulse',
  components:{
  //  VueTimepicker
  },
    props: ['venue','index'],
  data() {
    return {

    };
  },
  mounted(){

  },

}
</script>
 <style scoped>
        /* Add your custom styles here */
        body {
            font-family: 'Arial', sans-serif;
            line-height: 1.6;
            color: #333;
            margin: 0;
            padding: 0;
        }

        section {
            padding: 20px;
        }

       

        p {
            margin-bottom: 20px;
        }
    </style>