<template>
  <div >
    <h1>Pricing Model</h1>
    
    <div class="pricing-details mt-4">
      <div class="pricing-item">
        <div class="pricing-section transaction-fee ">
          <div class="left-side">
            <h2>Standard</h2>
            <p>Access a complete POS system with a simple, pay-as-you-go pricing. No setup fees, or hidden fees although you will need a device, either a laptop or tablet for each bar you wish to support.</p>
          </div>
          <div class="right-side">
            <h2>6% + $0.50</h2>
            <p>Per transaction on domestic cards</p>
          </div>
        </div>
      </div>

      <div class="pricing-item">
        <div class="pricing-section transaction-fee">
          <div class="left-side">
            <h2>Additional Services</h2>
           <p>- Free assistance for setting up your data</p>
           <p>- Onsite consultation available- please contact (443)-509-1389 </p>
           <p>- More features to be announced in the future, check for updates!</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'PricingPage'
};
</script>

<style scoped>

.pricing-details {
  display: flex;
}

.pricing-item {
  flex: 1;
  margin-right: 20px;
}

.pricing-section {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden; /* Ensure content doesn't overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-side {
  flex: 1;
  background-color: #f0f0f0; /* Grey background for left side */
  padding: 20px;
}

.right-side {
  flex: 1;
  padding: 20px;
}

.pricing-section h2 {
  font-size: 24px;
}

.pricing-section p {
  margin-bottom: 10px;
}

.additional-services {
  background-color: #f0f0f0; /* Light grey background for additional services */
}
</style>
