//import Vue from "vue";
import VueRouter from 'vue-router'
import ComingSoon from "../views/ComingSoon.vue"
import MenuItem from "../views/ItemMenu"
import MainLanding from "../views/MainLanding"
import SignIn from "../views/SignIn"
import ClubDetails from "../views/ClubDetails"
import LiveOrders from "../views/BarActions/LiveOrders.vue"
import BartenderView from "../views/BartenderView.vue"
import BarCreation from "../views/BarCreation.vue"
import BathroomCreation from "../views/BathroomCreation.vue"
import VenueList from "../views/VenueList.vue"
import GenerateBarcode from "../views/BarActions/GenerateBarcode.vue"
import LoginScreen from "../views/LoginScreen.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import SignUp from "../views/SignUp.vue"
import ResetPassword from "../views/ResetPassword.vue"
import Instructions from "../views/Instructions.vue"
import Accounts from "../views/Accounts.vue"
import EmployeeCode from "../views/EmployeeCode.vue"
import AboutMe from "../views/AboutMe.vue"
import PricingPage from "../views/Informational/PricingPage.vue"


//Vue.use(Router, NProgress);

var  routes= [
  {
    name: "Main-Landing",
    path: "/",
    component: MainLanding,
    props: { default: true, reload: false }
  },
  {
    name: "stripe-redirect",
    path: "/stripe-redirect",
    component: MainLanding,
    props: { default: true, reload: false }
  },
  {
    name: "Login",
    path: "/Login",
    component: LoginScreen,
    props:true
  },
  {
    name: "PricingPage",
    path: "/PricingPage",
    component: PricingPage
  },
  {
    name: "About",
    path: "/About",
    component: AboutMe,
  },
  {
    name: "Accounts",
    path: "/Accounts",
    component: Accounts,
    props:true,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "EmployeeCode",
    path: "/EmployeeCode",
    component: EmployeeCode,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Forgot-Password",
    path: "/Forgot-Password",
    component: ForgotPassword,
    
  },
  {
    name: "Instructions",
    path: "/Instructions",
    component: Instructions,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Reset-Password",
    path: "/Reset-Password/:resetToken",
    component: ResetPassword,
  },
  {
    name: "Generate-Barcode",
    path: "/Generate-Barcode",
    component: GenerateBarcode,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Venue-List",
    path: "/Venue-List/",
    component: VenueList,
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  
  {
    name: "Bathroom-View",
    path: "/Bathroom-View/",
    component: BathroomCreation,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Bartender-View",
    path: "/Bartender-View/",
    component: BartenderView,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Bar-Creation",
    path: "/Bar-Creation/",
    component: BarCreation,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Live-Orders",
    path: "/Live-Orders",
    component: LiveOrders,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Club-Details",
    path: "/Club-Details",
    component: ClubDetails,
    props: true,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "Sign-In",
    path: "/Sign-In",
    component: SignIn,
  },
  {
    name: "coming-soon",
    path: "/Coming-Soon",
    component: ComingSoon,
  },
  {
    name: "Menu",
    path: "/Menu",
    component: MenuItem,
    beforeEnter: (to, from, next) => {
      localStorage.getItem('token')!==null ? next() : next('/')
    }
  },
  {
    name: "SignUp",
    path: "/SignUp",
    component: SignUp
  },

]

const router = new VueRouter({
  routes, // short for `routes: routes`
  mode:'history'
})
/*
//router.use(NProgress)
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
 
  if (to.name) {
    // Start the route progress bar.

    try{
      this.$loading.show();
    }
    catch{
      // eslint-disable-next-line no-console
      console.log("Error occcured");  
    }
  
  }
  next();
});*/


export default router;
