<template>
    <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newBarModalLabel">Add New Bathroom</h5>
        <button type="button" class="btn-close" v-on:click="clear()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mt-3">
        <label for="name" class="form-label">Bathroom Name</label>
    <input type="email" class="form-control pl-3" v-model="name" id="exampleInputEmail1" aria-describedby="email">
  </div>
  <div class="mt-3">
        <label for="exampleInputEmail1" class="form-label">Gender</label>
   <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
  {{displayGender}}
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <div v-for="(item,index) in Genders" v-bind:key="index">
      <li><a class="dropdown-item" href="#" @click="changeGender(item)">{{item}}</a></li></div>
  </ul>
</div>
  </div>
  <div class="mt-3">
        <label for="exampleInputEmail1" class="form-label">Bathroom Location</label>
        <textarea class="form-control pl-3" v-model="bathroomDetails" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
  </div>
      </div>
      <div class="modal-footer">
        <button type="button" ref="close" class="btn btn-secondary" v-on:click="clear()" data-bs-dismiss="modal">Close</button>
        <button type="button" v-on:click="saveItem()" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
//import Genders from "../../StaticData.js"
import bathroomService from "../../api/bathroom.js"

export default {
  name: 'AddNewBathroom',
  props:["bathroom","venueId"],
  data() {
    return {
        name:"",
        gender:"",
        bathroomDetails:"",
        Genders:["Men","Women","Unisex"],
        update:false
    };
  },
  mounted(){

  },
  methods:{
    changeGender(gender){
      this.gender=gender
    },
    clear(){
      this.name="";
      this.gender=""
      this.bathroomDetails=""
      this.update=false
    },
    saveItem(){
        var bathroom = {
          name:this.name,
          gender:this.gender,
          venue_ID: this.$props.venueId,
          description: this.bathroomDetails,
          is_disabled:false
        }
        if(!this.update){
          bathroomService.saveNewBathroom(bathroom).then(data=>{
          this.$emit("addNewBathroom",data)
          this.clear()
           this.$refs.close.click();
        })
        }
        else{
          bathroomService.updateBathroomByID(this.$props.bathroom._id,bathroom).then(data=>{
          this.$emit("updateBathroom",data)
           this.clear()
           this.$refs.close.click();
        })
        }
    }
  },
  computed:{
    displayGender(){
      if(this.gender==""){
        return "Please Select"
      }
      else{
        return this.gender;
      }
    }
  },
  watch:{
        bathroom(){
         if(this.$props.bathroom!=""){
            this.name=this.$props.bathroom.name
            this.gender=this.$props.bathroom.gender
            this.bathroomDetails= this.$props.bathroom.description
            this.update=true
        }
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
