import Vuex from "vuex";
import VenueService from "../api/venue.js";
import UserService from "../api/user.js";
import createPersistedState from 'vuex-persistedstate';
import Vue from 'vue'

Vue.use(Vuex)


const store = new Vuex.Store({
    namespaced: true,
    plugins:[createPersistedState()],
    modules: {
    //    protocol: protocol
    },
    state: { 
       venues:[],
       loadedVenues:false,
        loggedInUser:'',
        isBusinessAccount:''},
        
    mutations: {
        setInitial(state){        
            state.venues = [],
            state.loadedVenues = false,
            state.loggedInUser = ''
            state.isBusinessAccount = ''
        },
        venueLookupValues(state, venues){
            state.venues = venues;
            state.loadedVenues = true;
            //state.bars = bars

        },
        lookupValues(state, lookupEvents) {
            state.VRCCSMLookUpValues = lookupEvents;
        },
       async loadUser(state,payload){
        if(payload.token){
            console.log(payload.data.user)
            if(payload.data.user.isBusinessAccount){
                localStorage.setItem('businessID',payload.data.user._id);
                
                localStorage.setItem('user',payload.data.user._id);
                localStorage.setItem('EmployeeCode',payload.data.user.randomCode);
                localStorage.setItem('isBusiness',true);
                
            }
            else{
                localStorage.setItem('businessID',payload.data.user.businessID);
                localStorage.setItem('user',payload.data.user._id);
            }
           //payload.data.user.isBusinessAccount);
            if(payload.data.user.stripeAccountID && payload.data.user.chargesEnabled){
                localStorage.setItem('stripeAccountID',payload.data.user.stripeAccountID);                

            }
            localStorage.setItem('token',payload.token);
            
            
            state.loggedInUser= payload.data.user.name
            state.isBusinessAccount = payload.data.user.isBusinessAccount
        }
        
       }

    },
    actions: {
        async login(context,credentials){
            context.commit("loadUser", await UserService.login(credentials))
        },
        async signup(context,credentials){
            context.commit("loadUser", await UserService.signUp(credentials))
        },
        async venueLookup(context){
            context.commit("venueLookupValues", await VenueService.getAllVenuesForUser(localStorage.getItem('businessID')))
        },
        async setInitial({ commit }) {
            commit('setInitial');
          },
    }
});


export default store;