<template>
  <div class="employee-association-container">
    <h2>Employee Association Code</h2>
    <p class="mt-2">When your employees make accounts have them use this to associate to your business</p>
    <div class="code-container">
      <!-- Loop through each digit and display it in a box -->
      <div v-for="(digit, index) in code" :key="index" class="digit-box">
        {{ digit }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Replace this with your actual 6-digit code
      code: localStorage.getItem('EmployeeCode')
    };
  },
};
</script>

<style scoped>
.employee-association-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.code-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.digit-box {
  border: 2px solid #000;
  width: 60px; /* Adjusted width for larger box */
  height: 60px; /* Adjusted height for larger box */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px; /* Adjusted font size for larger numbers */
  margin: 10px;
}
</style>