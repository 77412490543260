<template>
<div class="mt-3">
    <h1>User Details</h1>
        <form class="ms-4" @submit.prevent="signUp">
  <div class="mb-3">
    <div v-if="showError" class="alert alert-danger" role="alert">
          {{errorText}}
        </div>
    <div v-if="showSuccess" class="alert alert-danger" role="alert">
          Successfully added! Please check your email for activation.
        </div>
    <label for="inputClubName" class="form-label float-left">Name*</label>
    <input v-model="name" type="text" class="form-control ms-1" id="inputClubName" required>
  </div>
  <div class="mb-3">
    <label for="inputEmail" class="form-label float-left ">Email Address*</label>
    <input type="email" v-model="emailAddress" class="form-control ms-1" id="inputEmail" required>
  </div>
    <div class="mb-3">
    <label for="inputAddress" class="form-label float-left">Password*</label>
    <input type="password" v-model="password" class="form-control ms-1" id="inputAddress" required>
  </div>
<div class="mb-3">
    <label for="inputAddress" class="form-label float-left">Password Confirmation*</label>
    <input type="password" v-model="passwordConfirm" class="form-control ms-1" id="inputAddress" required>
  </div>
  <div class="mb-3">
    <label for="businessCode" class="form-label float-left">Business Code</label> <div class="tooltip-container">
    <!-- Add the 'data-bs-toggle' and 'data-bs-tooltip' attributes for Bootstrap tooltip -->
    <button class="tooltip-btn" type="button" v-on:click="alertInfo()" >
      <span class="question-mark">?</span>
    </button>
  </div>
    <input type="businessCode" v-model="businessCode" class="form-control ms-1" id="businessCode">
  </div>
  



    <button v-if="!showSuccess" type="submit" class="btn btnAccent">Submit</button>
</form>

</div>
</template>
<script>
//import VueTimepicker from 'vue2-timepicker'
import swal from 'sweetalert';
import 'vue2-timepicker/dist/VueTimepicker.css'
import userService from "../api/user.js"

export default {
  name: 'Club-Details',
  components:{
  //  VueTimepicker
  },
  data() {
    return {
        name:"",
        password:"",
        addressSecond:"",
        passwordConfirm:"",
        emailAddress:"",
        businessCode:"",
        showError:false,
        showSuccess:false,
        errorText:"",
        buttonPressed:false

    };
  },
  methods:{
    alertInfo(){
      swal("For Employees Only","Have whoever setup the business give you the 6 digit code to associate you to the business")
    },
    signUp(){
      console.log('here')
      if(!this.checkIfEmail(this.emailAddress)){
        this.showError=true;
        this.errorText="Not a valid email address"
      }
      else if(this.password.length<8){
        this.showError=true;
         this.errorText="Password must be at least 8 characters"
      }
      else if(this.password!=this.passwordConfirm){
        this.showError=true;
         this.errorText="Password and Password Confirm don't match"
      }
      else {
       console.log('Creating user')
        var user ={
            name:this.name,
            email: this.emailAddress,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            businessCode:this.businessCode
        }
            userService.signUp(user)
      .then(response =>{
        if(response.status=="success"){
          console.log('success')
          console.log(response)
          this.$store.commit('loadUser',response)
           this.$router.go()
        }
        else{
          console.log('failed')
           console.log(response)
          this.showError=true;
          this.errorText = response.data.message
        }

      });
        
      }
    },
    checkIfEmail(str) {
  // Regular expression to check if string is email
  const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

  return regexExp.test(str);
}
  },
  mounted(){
    if(this.isSignedIn){
     this.$router.push({ name: 'Main-Landing'})
    }
  },
  computed:{
     isSignedIn(){
        if(localStorage.getItem('token')){
          return true;
        }
        return false;
      }
  }
}
</script>
<style scoped>
.custom-label-height{
    height: 27px;
}
.tooltip-container {
  position: relative;
}

.tooltip-btn {
  background-color: grey;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 8px;
}

.tooltip-btn:hover {
  background-color: black;
}

.question-mark {

}

    

</style>