<template>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLabel">Order Reciept</h3>
                <button type="button" ref="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
                    <div class="card">
  <div class="card-body mx-4">
        <div class="container">
        <p class="my-4" style="font-size: 30px;">Checkout Order</p>
        <div class="row">
            <ul class="list-unstyled">
            <li class="text-muted mt-1 h4"><span class="text-black h4">Invoice:</span> {{order.readable_ID}}</li>
            </ul>
            <hr>
          
    <div class="row" v-for="(item, index) in $props.order.item_list" :key="index">
            <div class="col-xl-10">
            <p>{{item.NumberOfDrinks + ' x ' + item.Name}}</p>
            </div>
            <div class="col-xl-2">
            <p class="float-end">{{ '$'+item.Cost }}
            </p>
            </div>
            <hr v-if="index!=$props.order.item_list.length-1">
        </div>
       
            <hr style="border: 2px solid black;">
        </div>
        <div class="row text-black">

            <div class="col-xl-12">
            <p class="float-end fw-bold">Total: {{toDollarAmount(order.total_cost)}}
            </p>
            </div>
            <hr style="border: 2px solid black;">
        </div>


        </div>
        <h4>Notes</h4>
        <textarea v-if="!$props.order.is_accepted" v-model="notes" placeholder="Enter in notes to give to Customer. I.E reason for cancel or minor updates if neccesary"></textarea>
        <textarea v-else v-model="$props.order.acceptance_notes"   disabled></textarea>
    </div>

</div>

            </div>
            <div v-if="!$props.order.is_accepted" class="modal-footer">
        <button type="button" class="btn btn-secondary"  v-on:click="cancelOrder">Cancel Order</button>
        <button type="button" class="btn btn-primary" v-on:click="acceptOrder">Accept Order</button>
      </div>
      <div v-if="$props.order.is_accepted" class="modal-footer">
        <button type="button" class="btn btn-primary" v-on:click="completeOrder">Complete Order</button>
      </div>
        </div>
    </div>
</template>

<script>

import swal from 'sweetalert';

export default {
  name: 'CheckoutOrder',
  props: ['order'],
  data() {
    return {
     notes:""
    };
  },
  methods: {
    toDollarAmount(value){
      return '$' + value*.01
    },
    acceptOrder() {
          swal({
  title: 'Are you sure you want to accept this order',
  text: 'This will alert customer to come for pick up',
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  closeOnClickOutside: false,
})
  .then(() => {
          this.$props.order.acceptance_notes = this.notes
          this.$emit("acceptOrder",this.$props.order)
          this.$refs.close.click();
          this.notes= ''

      });
    },
    cancelOrder(){      
      swal({
  title: 'Are you sure you want to cancel this order',
  text: 'This will remove it from your order queue',
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  closeOnClickOutside: false,
})
  .then((value) => {
      if(value){
        this.$props.order.cancel_reason = this.notes
          this.$emit("cancelOrder",this.$props.order)
          this.$refs.close.click();
          this.notes= ''
      }
    });
  },
  completeOrder(){
    this.$emit('completeOrder',this.$props.order)
     this.$refs.close.click();
  }
  },
};
</script>

<style>
/* styles here */
</style>
