<template>
  <div class="hello screen-spacing">
    <label class="me-2 h4" for="dropdown">Select Venue:</label>
    <select id="dropdown" class="form-select" v-on:change="updateVenue($event)">
       <option v-for="(item,index) in venueList" :key="index" class="card mx-3" :value="item._id">{{item.venue_name}}</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'SelectVenue',
  data() {
    return {
        newItemType:"",
        barList:[],
        currentBar:"" ,
        venueId:0,
    };
  },
  computed:{
    venueList(){   
      if(this.$store.state.venues.length>0){
        var dataList = this.$store.state.venues.slice(0);
      dataList.unshift({_id:0,venue_name:"Please Select a Venue"});
      return dataList;
      }   
      return []
      
    },
  },
  methods:{
    updateVenue(e){
        this.$emit("updateVenue",e)
    },
    clear(){
        this.venueId=0;
        this.$emit("venueClear")
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
