import http from "./base";

class VenueService{
    constructor(){}

    async getAllVenuesForUser(userID) {
        const url = `venues/getAllVenues/`+userID;

        var response = "";
        await http
            .get(url, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async getVenueByID(id) {
        const url = `venues/`+id;

        var response = "";
        await http
            .get(url, {
                params:{
                  //  userId:userId
                },
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error.response;
            });

        return response;
    }

    async saveNewVenue(venueObject) {
        const url = `venues`;
        var response = "";
        await http
            .post(url, venueObject,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error.response.data;
            });

        return response;
    }

    async updateVenueByID(id,body) {
        const url = `venues/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async deleteVenueByID(id) {
        const url = `venues/`+id;
        var response = "";
        await http
            .delete(url,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }



}

let service = new VenueService();
export default service;



